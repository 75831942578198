<template>
  <div class="upgrade-dialog-wrapper">
    <div class="curren-plan-dialog-wrapper">
      <div class="title">{{ useTranslateI18n("payment.upgradeDialog.currentPlan") }}</div>
      <div class="name">{{ current_plan.name }}</div>
      <div class="period">
        {{ useTranslateI18n("payment.upgradeDialog.period", [currentPeriod]) }}
      </div>
      <div class="menu-item">
        <span class="text-1">
          {{ useTranslateI18n("payment.comboSub.t40") }}
        </span>
        <span class="text-2"> {{ current_plan.device_limit }} </span>
      </div>

      <div class="menu-item">
        <span class="text-1">
          {{ useTranslateI18n(`payment.paddleDialog.credits`, [useNumFormat(current_plan.credits)]) }}
        </span>
        <span class="text-2">
          {{ useTranslateI18n("payment.topCard[1].desc", [current_plan.singlePrice]) }}
        </span>
      </div>

      <div class="menu-item">
        <span class="text-1">
          {{ useTranslateI18n("payment.upgradeDialog.rCredits") }}
        </span>
        <span class="text-2"> {{ use_user.subscription_info_v2.sub_left_count }} </span>
      </div>

      <span class="tip-fl">
        {{ useTranslateI18n("payment.comboSub.t42") }}
      </span>
    </div>

    <div :class="['upgrade-plan-dialog-wrapper']">
      <div class="title">
        {{ useTranslateI18n("payment.comboSub.t75") }}
      </div>
      <div class="name">{{ next_plan.name }}</div>
      <div class="period">
        {{ useTranslateI18n("payment.upgradeDialog.period", [nextPeriod]) }}
      </div>
      <div class="menu-item">
        <span class="text-1">
          {{ useTranslateI18n("payment.comboSub.t40") }}
        </span>
        <span class="text-2"> {{ next_plan.device_limit }} </span>
      </div>
      <div class="menu-item">
        <span class="text-1">
          {{ useTranslateI18n("payment.account") }}
        </span>
        <span class="text-2"> {{ use_user.userEmail }} </span>
      </div>

      <div class="menu-item">
        <span class="text-1">
          {{ useTranslateI18n(`payment.paddleDialog.credits`, [useNumFormat(next_plan.credits)]) }}
        </span>
        <span class="text-2">
          {{
            useTranslateI18n("payment.topCard[1].desc", [
              next_plan.u_discount_per_credit_price || next_plan.singlePrice
            ])
          }}
        </span>
      </div>

      <div class="menu-item">
        <span class="text-1"> {{ useTranslateI18n("payment.buyDataPkgDialog.total") }} </span>
        <span class="text-2 text-n"> {{ next_plan.u_discount_price || next_plan.price }} </span>
      </div>

      <div v-if="next_plan.u_discount_price" class="menu-item menu-item-after-offer">
        <span class="text-1">
          {{ useTranslateI18n("payment.comboSub.t52", [getLocalDateString(next_plan.period_end, "YYYY/MM/DD")]) }}
        </span>
        <span class="text-2 text-n"> {{ next_plan.price }} </span>
      </div>

      <!-- <span class="tip-fl tip-r"> Include Sales Tax US$13(10%) </span> -->
    </div>

    <CommonButton ref="btnRef" class="submit-button" :button-style="buttonStyle1" @btn-click="submit"
      ><CommonAnimationScrollText>{{
        useTranslateI18n("payment.topCard[1].btn[1]")
      }}</CommonAnimationScrollText></CommonButton
    >
  </div>
</template>

<script setup lang="ts">
import { ICardDetailData } from "@/types/components/pages/combo/comboCardT"

import { useUser } from "@/store/user"
let use_user = useUser()

interface IProps {
  current_plan: ICardDetailData & INewComboProductListObj
  next_plan: ICardDetailData & INewComboProductListObj
}

const props = withDefaults(defineProps<IProps>(), {
  current_plan: () => {
    return {} as ICardDetailData & INewComboProductListObj
  },
  next_plan: () => {
    return {} as ICardDetailData & INewComboProductListObj
  }
})

let btnRef = ref()

const emit = defineEmits(["btnClick"])

const closeLoading = () => {
  btnRef.value && btnRef.value.closeLoading()
}

const currentPeriod = computed(() => {
  return `${getLocalDateString(props.current_plan.period_start, "YYYY-MM-DD")} - ${getLocalDateString(
    props.current_plan.period_end,
    "YYYY-MM-DD"
  )}`
})

const nextPeriod = computed(() => {
  return `${getLocalDateString(props.next_plan.period_start, "YYYY-MM-DD")} - ${getLocalDateString(
    props.next_plan.period_end,
    "YYYY-MM-DD"
  )}`
})

const buttonStyle1 = reactive({
  width: "100%",
  background: "#FFE050",
  borderRadius: "60px",
  height: "42px",
  color: "#000"
})

const submit = useDebounceFn(() => {
  btnRef.value && btnRef.value.openLoading()
  emit("btnClick", { closeLoading })
}, 300)
</script>

<style scoped lang="scss">
.upgrade-dialog-wrapper {
  display: flex;
  flex-direction: column;
  line-height: normal;
  color: #000;
  .curren-plan-dialog-wrapper,
  .upgrade-plan-dialog-wrapper {
    display: flex;
    flex-direction: column;
    padding-bottom: 16px;
    border-bottom: 0.5px solid rgba(0, 0, 0, 0.15);
    &-border {
      border-bottom: none;
    }
    .title {
      font-size: 14px;
      margin-bottom: 8px;
    }
    .name {
      font-size: 24px;
      @include fontSemibold;
      margin-bottom: 24px;
    }
    .period {
      font-size: 14px;
    }
    .menu-item {
      display: flex;
      margin-top: 18px;
      justify-content: space-between;
      align-items: center;
      .text-1 {
        font-size: 14px;
      }
      .text-2 {
        font-size: 16px;
        @include fontSemibold;
      }
      .text-n {
        color: #ff6b00;
      }
    }
    .menu-item-after-offer {
      margin-top: 18px;
      border-top: 0.5px solid rgba(0, 0, 0, 0.15);
      padding-top: 18px;
    }
    .tip-fl {
      margin-top: 5px;
      font-size: 12px;
      color: rgba(0, 0, 0, 0.55);
      @include fontMedium;
    }
    .tip-r {
      display: flex;
      justify-content: flex-end;
    }
  }

  .upgrade-plan-dialog-wrapper {
    padding-top: 24px;
  }

  .submit-button {
    margin-top: 24px;
  }
}
</style>
