import { createVNode, render } from "vue"
import Payment from "~~/components/pages/comboSub/Payment.vue"
import { ISuccessType } from "@/types/components/pages/comboSub"
import CancelSubDialog from "~~/components/pages/comboSub/CancelDialog.vue"
import { Payment_Method } from "@/constant/pages/combo"
import { ICardDetailData } from "@/types/components/pages/combo/comboCardT"
import { PaymentStatusType } from "@/types/components/common/paymentStatus"
import { useUser } from "@/store/user"
import { PROFILE_PAGE_PATH } from "@/constant/route" // 邀请注册页面路由
import { TabListValue } from "@/constant/pages/profile"
import { SessStorageKey } from "@/constant/cache"

type UseCookieDialogOptions = {
  onClose?: () => void
  onSubmit?: (...args: any[]) => any
  payMethods?: Payment_Method[]
  data?: ICardDetailData & INewComboProductListObj
  desc?: string
}

let showSubBuyDialog = false
let upgradeDialog = false
let downgradeDialog = false
let reactivatedDialog = false
// 订阅过或者没订阅过 点击补充包或者Pay as You Go套餐选择支付方式
export function useComboSubBuyDialog(options: UseCookieDialogOptions = {}) {
  if (showSubBuyDialog) {
    return
  }
  const vm = createVNode(Payment, {
    ...options,
    onClose() {
      showSubBuyDialog = false
    }
  })
  const { vueApp } = useNuxtApp()
  vm.appContext = vueApp._context
  render(vm, document.createElement("div"))
  showSubBuyDialog = true
}

// 撤销降级或者撤销取消订阅 成功弹窗
export const useCancelDowngradeOrCancelSubDialogSuccess = (type: ISuccessType) => {
  useCommonNormalDialog({
    dialogWrapperStyle: {
      padding: "40px"
    },
    close() {
      location.reload()
    },
    popperClass: "sign-in-or-sign-up-wrapper-dialog",
    contentSlots({ close }) {
      const com = resolveComponent("PagesComboSubSuccess")
      return (
        <com
          itype={type}
          submit={async ({ closeLoading }) => {
            close()
            location.reload()
          }}
        />
      )
    }
  })
}

// 升级弹窗
export const useComboSubUpgradeDialog = (options: {
  id: number
  current_plan: ICardDetailData & INewComboProductListObj
  next_plan: ICardDetailData & INewComboProductListObj
  successCallback: (...args: any[]) => any
  errorCallback: (...args: any[]) => any
}) => {
  if (upgradeDialog) {
    return
  }
  useCommonNormalDialog({
    dialogWrapperStyle: {
      padding: "40px"
    },
    close() {
      upgradeDialog = false
    },
    width: 430,
    popperClass: "sign-in-or-sign-up-wrapper-dialog",
    contentSlots({ close }) {
      const com = resolveComponent("PagesComboSubUpgrade")
      return (
        <com
          {...options}
          onBtnClick={async ({ closeLoading }) => {
            try {
              const { trade_no } = await postNewSubUpdate({
                product_id: options.id,
                purchase_uuid: usePurchaseReferer(),
                purchase_source: useRefWebRefferer()
              })
              options.successCallback && options.successCallback(trade_no)
            } catch (e) {
              options.errorCallback && options.errorCallback()
            } finally {
              close()
              closeLoading()
            }
          }}
        />
      )
    }
  })
  upgradeDialog = true
}

// 降级弹窗
export const useComboSubDownGradeDialog = (options: {
  id: number
  current_plan: ICardDetailData & INewComboProductListObj
  next_plan: ICardDetailData & INewComboProductListObj
  successCallback: (...args: any[]) => any
  errorCallback: (...args: any[]) => any
}) => {
  if (downgradeDialog) {
    return
  }
  useCommonNormalDialog({
    dialogWrapperStyle: {
      padding: "40px"
    },
    width: 876,
    close() {
      downgradeDialog = false
    },
    popperClass: "sign-in-or-sign-up-wrapper-dialog",
    contentSlots({ close }) {
      const com = resolveComponent("PagesComboSubDownGrade")
      return (
        <com
          {...options}
          onBtnClick={async ({ closeLoading }) => {
            try {
              const { trade_no } = await postNewSubUpdate({
                product_id: options.id,
                purchase_uuid: usePurchaseReferer(),
                purchase_source: useRefWebRefferer()
              })
              options.successCallback && options.successCallback(trade_no)
            } catch (e) {
              options.errorCallback && options.errorCallback()
            } finally {
              close()
              closeLoading()
            }
          }}
        />
      )
    }
  })
  downgradeDialog = true
}

// 取消订阅填写原因弹窗
export const useComboSubCancelDialog = (lastClose?: (...args: any[]) => any) => {
  const vm = createVNode(CancelSubDialog, {
    ref: "cancelSubDialogRef",
    lastClose
  })
  const { vueApp } = useNuxtApp()
  vm.appContext = vueApp._context
  render(vm, document.createElement("div"))
}

// 订阅reactiva提示弹窗
export const useComboSubPausedDialog = () => {
  if (getLocalStorage(SessStorageKey.IS_SHOW_REACTIVATED_DIALOG, "sessionStorage")) return
  useCommonNormalDialog({
    dialogWrapperStyle: {
      padding: "40px"
    },
    popperClass: "sign-in-or-sign-up-wrapper-dialog",
    contentSlots({ close }) {
      const com = resolveComponent("PagesComboSubPaused")
      return (
        <com
          onSubmit={async () => {
            close()
            const { trade_no } = await postNewSubRecover()
            useComboSubPausedResultDialog(trade_no)
          }}
        />
      )
    }
  })
  setLocalStorage(SessStorageKey.IS_SHOW_REACTIVATED_DIALOG, "true", "sessionStorage")
}

// 订阅reactivate之后的结果弹窗
export const useComboSubPausedResultDialog = (trade_no: string) => {
  if (reactivatedDialog) {
    return
  }

  const showCloseIcon = ref(false)

  useCommonNormalDialog({
    showCloseIcon,
    dialogWrapperStyle: {
      padding: "40px"
    },
    popperClass: "sign-in-or-sign-up-wrapper-dialog",
    close() {
      reactivatedDialog = false
    },
    contentSlots({ close }) {
      const com = resolveComponent("PagesComboSubPaymentStatus")
      return (
        <com
          trade_no={trade_no}
          onBtnClick={(type: PaymentStatusType) => {
            const use_user = useUser()
            if (type === PaymentStatusType.Success) {
              if (useRoute().fullPath.includes(unref(PROFILE_PAGE_PATH))) {
                return location.reload()
              }
              navigateTo(`${unref(PROFILE_PAGE_PATH)}?t=${TabListValue.MY_BILLING}`)
            } else if (type === PaymentStatusType.Failed) {
              window.open(use_user.subscription_info_v2.payment_updated_url, "_blank")
            }
          }}
          onResult={() => {
            showCloseIcon.value = true
          }}
        />
      )
    }
  })

  reactivatedDialog = true
}
