<template>
  <CommonDialog v-model="showDialog" popper-class="cancel-payment_dialog" :dialog-style="dialogStyle" @close="onClose">
    <div class="d-text">
      <div class="title">
        {{ title }}
      </div>
      <div class="content">
        <slot />
      </div>
      <div class="control-btn">
        <CommonButton :button-style="cancelStyle" @btn-click="cancelClick">
          <CommonAnimationScrollText>{{
            useTranslateI18n(`profile.billingUsage.sub.cancelDialog.btn[0]`)
          }}</CommonAnimationScrollText>
        </CommonButton>
        <CommonButton ref="btnRef" :button-style="submitStyle" @btn-click="submitClick">
          <CommonAnimationScrollText>{{
            useTranslateI18n(`profile.billingUsage.sub.cancelDialog.btn[1]`)
          }}</CommonAnimationScrollText>
        </CommonButton>
      </div>
    </div>
  </CommonDialog>
</template>

<script setup lang="ts">
import { CSSProperties } from "vue"
import variables from "@/assets/scss/responsiveExport.module.scss"
import CommonAnimationScrollText from "@/components/common/Animation/ScrollText.vue"

interface IProps {
  title: string
  onClose?: () => void
  submit: (...args: any[]) => any
  cancel: (...args: any[]) => any
}
const props = withDefaults(defineProps<IProps>(), {
  title: () => "",
  onClose: null,
  submit: () => {
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    return () => {}
  },
  cancel: () => {
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    return () => {}
  }
})

const btnRef = ref()

const onClose = () => {
  props.onClose && props.onClose()
}

let dialogStyle = reactive<CSSProperties>({
  width: 438,
  backgroundColor: "#fff",
  padding: "40px"
})

const showDialog = ref(true)

const submitStyle = reactive({
  width: "100%",
  height: "42px",
  borderRadius: "60px",
  fontSize: "14px",
  color: "rgba(0, 0, 0, 0.85)",
  backgroundColor: variables.bgColor
})

const cancelStyle = reactive({
  width: "100%",
  height: "42px",
  borderRadius: "60px",
  fontSize: "14px",
  color: "rgba(0, 0, 0, 0.85)",
  backgroundColor: "rgba(0, 0, 0, 0.05)"
})

const submitClick = () => {
  props.submit({ btnRef: btnRef.value, closeDialog })
}

const closeDialog = () => {
  showDialog.value = false
}

const cancelClick = () => {
  props.cancel()
  closeDialog()
}

defineExpose({
  closeDialog
})
</script>

<style lang="scss">
.welcome-page-dialog {
  &.cancel-payment_dialog {
    .d-text {
      .title {
        font-size: 24px;
        line-height: 29px;
        @include fontSemibold;
      }
      .content {
        margin-top: 24px;
        font-size: 14px;
        line-height: 22px;
        color: rgba(0, 0, 0, 0.55);
      }
      .control-btn {
        display: flex;
        margin-top: 40px;
        .common-button {
          flex: 1;
          &:nth-last-child(1) {
            margin-left: 8px;
          }
        }
      }
    }
  }
}
</style>
