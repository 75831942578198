import PagesPaymentCancelDialog from "@/components/pages/payment/CancelDialog.vue"
import { createVNode, render } from "vue"

let showCancelDialog = false
export const usePaymentCancelDialog = ({
  title,
  content,
  submit,
  cancel
}: {
  title: string
  content: (...args: any[]) => VNode
  submit: (...args: any[]) => any
  cancel?: (...args: any[]) => any
}) => {
  if (showCancelDialog) {
    return
  }
  const vm = createVNode(
    PagesPaymentCancelDialog,
    {
      title,
      submit,
      cancel,
      onClose() {
        showCancelDialog = false
      },
      ref: "cancelDialogRef"
    },
    {
      default: () => {
        return content()
      }
    }
  )
  const { vueApp } = useNuxtApp()
  vm.appContext = vueApp._context
  render(vm, document.createElement("div"))
  showCancelDialog = true
}
