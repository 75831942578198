<template>
  <div class="sub-paused-w">
    <div class="title">
      {{ useTranslateI18n("payment.comboSub.t46") }}
    </div>
    <div class="desc">
      {{ useTranslateI18n("payment.comboSub.t47") }}
    </div>

    <CommonButton ref="btnRef" class="submit-button" :button-style="buttonStyle1" @btn-click="submit"
      ><CommonAnimationScrollText>{{
        useTranslateI18n("payment.topCard[1].btn[4]")
      }}</CommonAnimationScrollText></CommonButton
    >
  </div>
</template>

<script setup lang="ts">
interface IProps {
  onSubmit: (...args: any[]) => any
}

const props = withDefaults(defineProps<IProps>(), {})

let btnRef = ref()

const buttonStyle1 = reactive({
  width: "100%",
  background: "#FFE050",
  color: `rgba(0, 0, 0, 0.85)`,
  borderRadius: "60px",
  height: "42px",
  fontSize: "14px",
  fontFamily: "Inter-Medium"
})

const submit = () => {
  props.onSubmit && props.onSubmit()
  btnRef.value && btnRef.value.openLoading()
}
</script>

<style scoped lang="scss">
.sub-paused-w {
  color: #000;
  .title {
    font-size: 24px;
    @include fontSemibold;
    margin-bottom: 24px;
  }
  .desc {
    font-size: 14px;
    color: rgba(0, 0, 0, 0.55);
    line-height: 22px;
  }
  .submit-button {
    margin-top: 40px;
  }
}
</style>
