export default function useLogger() {
  // 检查是否为开发环境
  const isDev = process.env.NUXT_APP_ENV === "development"

  // 封装的 log 函数
  const log = (...args: any[]) => {
    if (isDev) {
      console.log(...args) // 只有在开发环境中打印
    }
  }

  return {
    log,
    warn: (...args: any[]) => {
      if (isDev) {
        console.warn(...args)
      }
    },
    error: (...args: any[]) => {
      if (isDev) {
        console.error(...args)
      }
    }
  }
}
