export enum ISuccessType {
  downgrade = 1,
  cancel = 2
}

// 我的账单订单状态
export enum IComboSubOrderStatus {
  UseUp = 1,
  RollOver,
  Expired,
  Cancelled,
  Paused,
  Refunding,
  Refunded
}
