<template>
  <div :class="['success-wrapper']">
    <div class="s-icon">🎉</div>
    <div class="s-t-1">
      {{ title }}
    </div>
    <CommonButton ref="submitRef" :button-style="submitStyle" class="submit-btn" @btn-click="submitClick">
      <CommonAnimationScrollText>
        {{ useTranslateI18n(`login.email.done`) }}
      </CommonAnimationScrollText>
    </CommonButton>
  </div>
</template>

<script setup lang="ts">
import { ISuccessType } from "@/types/components/pages/comboSub"

interface Props {
  itype: ISuccessType
  submit: (...args: any[]) => any
}

const props = withDefaults(defineProps<Props>(), {
  itype() {
    return ISuccessType.cancel
  }
})

let submitRef = ref()

let closeLoading = () => {
  submitRef.value && submitRef.value.closeLoading()
}

const title = computed(() => {
  return props.itype === ISuccessType.cancel
    ? useTranslateI18n("payment.comboSub.t70")
    : useTranslateI18n("payment.comboSub.t69")
})

const submitStyle = reactive({
  width: "100%",
  height: "42px",
  borderRadius: "60px",
  fontSize: "14px",
  color: "#000",
  backgroundColor: "#FFE050"
})

const submitClick = () => {
  submitRef.value && submitRef.value.openLoading()
  props.submit &&
    props.submit({
      closeLoading
    })
}
</script>

<style scoped lang="scss">
.success-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  line-height: normal;
  text-align: center;

  .s-icon {
    font-size: 85px;
    margin-bottom: 30px;
    color: #000;
  }
  .s-t-1 {
    margin-bottom: 16px;
    font-size: 24px;
    @include fontSemibold;
    color: #000;
  }
  .s-t-2 {
    font-size: 14px;
    line-height: 22px;
    color: rgba(0, 0, 0, 0.55);
    @include fontMedium;
    margin-bottom: 40px;
  }

  &-1 {
    .s-icon {
      margin-bottom: 0;
    }
    .s-t-1 {
      margin-top: 24px;
      margin-bottom: 40px;
    }
    .s-t-2 {
      display: none;
    }
  }
}
</style>
