<template>
  <div class="downgrade-dialog-wrapper">
    <div class="left-w">
      <div class="curren-plan-dialog-wrapper">
        <div class="title">{{ useTranslateI18n("payment.upgradeDialog.currentPlan") }}</div>
        <div class="name">{{ current_plan.name }}</div>
        <div class="period">
          {{ useTranslateI18n("payment.upgradeDialog.period", [currentPeriod]) }}
        </div>
        <div class="menu-item">
          <span class="text-1">
            {{ useTranslateI18n("payment.comboSub.t40") }}
          </span>
          <span class="text-2"> {{ current_plan.device_limit }} </span>
        </div>

        <div class="menu-item">
          <span class="text-1">
            {{ useTranslateI18n(`payment.paddleDialog.credits`, [useNumFormat(current_plan.credits)]) }}
          </span>
          <span class="text-2">
            {{ useTranslateI18n("payment.topCard[1].desc", [current_plan.singlePrice]) }}
          </span>
        </div>

        <div class="menu-item">
          <span class="text-1">{{ useTranslateI18n("payment.buyDataPkgDialog.total") }} </span>
          <span class="text-2"> {{ current_plan.price }} </span>
        </div>
      </div>

      <div class="upgrade-plan-dialog-wrapper">
        <div class="title">
          {{ useTranslateI18n("payment.comboSub.t74") }}
        </div>
        <div class="name">{{ next_plan.name }}</div>
        <div class="period">
          {{ useTranslateI18n("payment.upgradeDialog.period", [nextPeriod]) }}
        </div>
        <div class="menu-item">
          <span class="text-1">
            {{ useTranslateI18n("payment.comboSub.t40") }}
          </span>
          <span class="text-2"> {{ next_plan.device_limit }} </span>
        </div>
        <div class="menu-item">
          <span class="text-1">
            {{ useTranslateI18n("payment.account") }}
          </span>
          <span class="text-2"> {{ use_user.userEmail }} </span>
        </div>

        <div class="menu-item">
          <span class="text-1">
            {{ useTranslateI18n(`payment.paddleDialog.credits`, [useNumFormat(next_plan.credits)]) }}
          </span>
          <span class="text-2">
            {{ useTranslateI18n("payment.topCard[1].desc", [next_plan.singlePrice]) }}
          </span>
        </div>

        <div class="menu-item">
          <span class="text-1"> {{ useTranslateI18n("payment.buyDataPkgDialog.total") }} </span>
          <span class="text-2 text-n"> {{ next_plan.price }} </span>
        </div>

        <div class="menu-item">
          <span class="text-1 text-b">
            {{ useTranslateI18n("payment.upgradeDialog.aCharged", [use_user.subscription_info_v2.next_payment]) }}
          </span>
          <span class="text-2 text-n"> {{ next_plan.price }} </span>
        </div>

        <!-- <span class="tip-fl tip-r"> Include Sales Tax US$13(10%) </span> -->
      </div>
    </div>

    <div class="right-w">
      <div class="sub-list">
        <div v-for="(item, i) in comboSubDescList" :key="i" class="sub-list-item">
          <div class="item-t" v-html="item"></div>
        </div>
      </div>
      <CommonButton ref="btnRef" class="submit-button" :button-style="buttonStyle1" @btn-click="submit"
        ><CommonAnimationScrollText>{{
          useTranslateI18n("payment.topCard[1].btn[3]")
        }}</CommonAnimationScrollText></CommonButton
      >
    </div>
  </div>
</template>

<script setup lang="ts">
import { useUser } from "@/store/user"
import { ICardDetailData } from "@/types/components/pages/combo/comboCardT"

interface IProps {
  current_plan: ICardDetailData & INewComboProductListObj
  next_plan: ICardDetailData & INewComboProductListObj
}

const props = withDefaults(defineProps<IProps>(), {
  current_plan: () => {
    return {} as ICardDetailData & INewComboProductListObj
  },
  next_plan: () => {
    return {} as ICardDetailData & INewComboProductListObj
  }
})

const emit = defineEmits(["btnClick"])

let use_user = useUser()

let comboSubDescList = ref([
  useTranslateI18n("payment.topCard[1].footerCon[1]"),
  useTranslateI18n("payment.comboSub.t43", [{ type: "static", class: "text-b" }]),
  useTranslateI18n("payment.comboSub.t44", [
    { text: useNumFormat(props.current_plan.credits) },
    { text: useNumFormat(props.next_plan.credits) },
    { type: "static", class: "text-b" }
  ])
])

let btnRef = ref()

const closeLoading = () => {
  btnRef.value && btnRef.value.closeLoading()
}

const buttonStyle1 = reactive({
  width: "100%",
  background: "rgba(0, 0, 0, 0.10)",
  borderRadius: "60px",
  height: "42px",
  color: "#000"
})

const currentPeriod = computed(() => {
  return `${getLocalDateString(props.current_plan.period_start, "YYYY-MM-DD")} - ${getLocalDateString(
    props.current_plan.period_end,
    "YYYY-MM-DD"
  )}`
})

const nextPeriod = computed(() => {
  return `${getLocalDateString(props.next_plan.period_start, "YYYY-MM-DD")} - ${getLocalDateString(
    props.next_plan.period_end,
    "YYYY-MM-DD"
  )}`
})

const submit = useDebounceFn(() => {
  btnRef.value && btnRef.value.openLoading()
  emit("btnClick", { closeLoading })
}, 300)
</script>

<style scoped lang="scss">
.downgrade-dialog-wrapper {
  display: flex;
  flex-direction: row;
  line-height: normal;
  color: #000;
  > div {
    width: 50%;
  }
  .left-w {
    display: flex;
    flex-direction: column;
    padding-right: 40px;
    border-right: 0.5px solid rgba(0, 0, 0, 0.15);
    .curren-plan-dialog-wrapper,
    .upgrade-plan-dialog-wrapper {
      display: flex;
      flex-direction: column;
      padding-bottom: 16px;
      .title {
        font-size: 14px;
        margin-bottom: 8px;
      }
      .name {
        font-size: 24px;
        @include fontSemibold;
        margin-bottom: 24px;
      }
      .period {
        font-size: 14px;
      }
      .menu-item {
        display: flex;
        margin-top: 18px;
        justify-content: space-between;
        align-items: center;
        .text-1 {
          font-size: 14px;
          &.text-b {
            color: #ff6b00;
          }
        }
        .text-2 {
          font-size: 16px;
          @include fontSemibold;
        }
        .text-n {
          @include fontMedium;
          color: #ff6b00;
        }
      }
      .tip-fl {
        margin-top: 5px;
        font-size: 12px;
        color: rgba(0, 0, 0, 0.55);
        @include fontMedium;
      }
      .tip-r {
        display: flex;
        justify-content: flex-end;
      }
    }

    .upgrade-plan-dialog-wrapper {
      padding-top: 24px;
    }
  }
  .right-w {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding-left: 40px;

    .sub-list {
      display: flex;
      flex-direction: column;
      &-item {
        font-size: 14px;
        line-height: normal;
        color: rgba(0, 0, 0, 0.55);
        margin-bottom: 8px;
        display: flex;
        &::before {
          content: "";
          margin-top: 5px;
          width: 5px;
          height: 5px;
          border-radius: 50%;
          background-color: rgba(0, 0, 0, 0.55);
          display: inline-block;
          margin-right: 10px;
          flex-shrink: 0;
        }
        &:nth-last-child(1) {
          margin-bottom: 0;
        }
        :deep(.text-b) {
          color: rgba(0, 0, 0, 0.85);
        }
      }
    }
  }
  .submit-button {
    margin-top: 24px;
  }
}

@include device-max-width-custom(970px) {
  .downgrade-dialog-wrapper {
    display: flex;
    flex-direction: column;
    > div {
      width: 100%;
    }
    .left-w {
      padding-right: 0;
      border-right: 0;
    }
    .right-w {
      padding-left: 0;
    }
  }
}
</style>
