<template>
  <CommonDialog
    v-model="showCancelSubDialog"
    :dialog-style="dialogContentStyle"
    popper-class="cancel-sub_dialog"
    @close="closeDialog"
  >
    <div v-if="showHandler.first" class="first-checkbox-list">
      <div class="first-checkbox-title">{{ useTranslateI18n(`help.moreDialog.sorry`) }}</div>
      <div class="first-checkbox-title2">{{ useTranslateI18n(`help.moreDialog.cancel`) }}</div>
      <CommonForm
        ref="formRef"
        form-label-position="top"
        :form-inline="false"
        :form-input-list="formInputList"
        :form-model="formModel"
      />
      <div class="btn-list">
        <div class="btn">
          <CommonButton @btn-click="cancelDialog">
            <CommonAnimationScrollText> {{ useTranslateI18n(`help.moreDialog.can`) }} </CommonAnimationScrollText>
          </CommonButton>
        </div>
        <div class="btn">
          <CommonButton :class="[formModel.checkbox.length ? 'selected' : '']" @btn-click="conitueClick">
            <CommonAnimationScrollText> {{ useTranslateI18n(`help.moreDialog.continue`) }} </CommonAnimationScrollText>
          </CommonButton>
        </div>
      </div>
    </div>
    <div v-if="showHandler.second" class="confirm-cancel">
      <div class="confirm-cancel-title">{{ useTranslateI18n(`help.moreDialog.confirm`) }}</div>
      <div class="sub-desc">
        <div
          class="sub-desc-current"
          v-html="
            useTranslateI18n(`help.moreDialog.currentSub`, [
              {
                text: subscription_info_v2.plan_name,
                class: 'name-1'
              },
              {
                text: subscription_info_v2.next_payment,
                class: 'name-2'
              }
            ])
          "
        ></div>
        <div class="sub-desc-current d1">
          {{ useTranslateI18n(`help.moreDialog.time`) }}
        </div>
      </div>
      <div class="sub-desc-after">
        <div class="sub-desc-after-title">{{ useTranslateI18n(`help.moreDialog.sub`) }}</div>
        <div class="sub-desc-after-list">
          <ul>
            <li>{{ useTranslateI18n(`payment.comboSub.t45`) }}</li>
            <li>{{ useTranslateI18n(`help.moreDialog.subCredits`) }}</li>
            <li>{{ useTranslateI18n(`help.moreDialog.bill`) }}</li>
          </ul>
        </div>
        <div class="list-bottom-desc">{{ useTranslateI18n(`help.moreDialog.toCancel`) }}</div>
      </div>
      <div class="btn-list">
        <div class="btn">
          <CommonButton ref="cancelBtnRef" @click="cancelBtnClick">
            <CommonAnimationScrollText> {{ useTranslateI18n(`help.moreDialog.cancelSub`) }} </CommonAnimationScrollText>
          </CommonButton>
        </div>
        <div class="btn">
          <CommonButton @click="cancelDialog">
            <CommonAnimationScrollText>{{ useTranslateI18n(`help.moreDialog.stay`) }}</CommonAnimationScrollText>
          </CommonButton>
        </div>
      </div>
      <div class="bottom-desc">
        {{ useTranslateI18n(`help.moreDialog.note`) }}
      </div>
    </div>
    <div v-if="showHandler.third" class="cancel-ok">
      <div class="cancel-ok-title">{{ useTranslateI18n(`help.moreDialog.subCancel`) }}</div>
      <div class="cancel-ok-content">
        {{ useTranslateI18n(`help.moreDialog.mistake`) }}
        <span @click="goBilling">{{ useTranslateI18n(`help.moreDialog.billing`) }}</span>
      </div>
      <CommonButton @btn-click="cancelDialog">
        <CommonAnimationScrollText>{{ useTranslateI18n(`help.moreDialog.ok`) }}</CommonAnimationScrollText>
      </CommonButton>
    </div>
  </CommonDialog>
</template>

<script setup lang="tsx">
import { IFormPropsInputList } from "@/types/components/common/form"
import { useUser } from "@/store/user"
import { PROFILE_PAGE_PATH } from "@/constant/route"
import { TabListValue } from "@/constant/pages/profile"
// import CommonDialog from "@/components/common/Dialog.vue"
// import CommonForm from "@/components/common/Form.vue"
// import CommonButton from "@/components/common/Button.vue"

interface IProps {
  lastClose: (...args: any[]) => any
}

const props = withDefaults(defineProps<IProps>(), {
  lastClose: () => null
})

const use_user = useUser()
const { subscription_info_v2 } = storeToRefs(use_user)

function useCheckForm() {
  // 取消订阅弹窗
  let showCancelSubDialog = ref(true)

  let cancelBtnRef = ref()

  let showHandler = reactive({
    first: true,
    second: false,
    third: false
  })

  let dialogContentStyle = reactive({
    width: 438,
    padding: "40px",
    backgroundColor: "#fff"
  })

  let formModel = reactive({
    checkbox: [],
    text: ""
  })

  let extraCheckboxId = null

  let formInputList = ref<IFormPropsInputList<typeof formModel>>([
    {
      model: "checkbox",
      type: "checkbox",
      options: []
    },
    {
      showItem: false,
      model: "text",
      label: useTranslateI18n(`help.dialogForm.comment`),
      clearable: true,
      type: "textarea",
      autosize: true,
      resize: "none",
      placeholder: useTranslateI18n(`help.dialogForm.placeholder`)
    }
  ])

  watch(
    () => formModel.checkbox,
    (newValue) => {
      formInputList.value[1].showItem = newValue.includes(extraCheckboxId)
    }
  )

  getEnumList(3).then((data) => {
    formInputList.value[0].options = data.map((item) => {
      if (item.sub_type === 2) {
        extraCheckboxId = item.id
      }
      return {
        label: item.name,
        value: item.id
      }
    })
  })

  const cancelDialog = () => {
    showCancelSubDialog.value = false
    if (showHandler.third) {
      props.lastClose && props.lastClose()
    }
  }

  const closeDialog = () => {
    if (showHandler.third) {
      props.lastClose && props.lastClose()
    }
    showClickSide("first")
    formModel.checkbox = []
    formModel.text = ""
  }

  // 继续点击
  const conitueClick = () => {
    if (formModel.checkbox.length) {
      showClickSide("second")
    }
  }

  // 确认取消订阅点击
  const cancelBtnClick = async () => {
    cancelBtnRef.value.openLoading()
    await postNewSubCancel({ reason_id: formModel.checkbox, reason_content: formModel.text })
    await use_user.setAllUserInfo()
    cancelBtnRef.value.closeLoading()
    showClickSide("third")
  }

  // 个人账单
  function goBilling() {
    if (process.client) {
      cancelDialog()
      navigateTo(`${unref(PROFILE_PAGE_PATH)}?t=${TabListValue.MY_BILLING}`)
    }
  }

  const showClickSide = (name) => {
    for (let key in showHandler) {
      if (key === name) {
        showHandler[key] = true
      } else {
        showHandler[key] = false
      }
    }
  }

  return {
    formModel,
    formInputList,
    showCancelSubDialog,
    dialogContentStyle,
    showHandler,
    cancelBtnRef,
    cancelDialog,
    goBilling,
    conitueClick,
    closeDialog,
    cancelBtnClick
  }
}

let {
  formModel,
  formInputList,
  showCancelSubDialog,
  dialogContentStyle,
  showHandler,
  cancelBtnRef,
  goBilling,
  cancelDialog,
  closeDialog,
  conitueClick,
  cancelBtnClick
} = useCheckForm()
</script>

<style lang="scss" scoped>
.btn-list {
  margin-top: 40px;
  display: flex;
  .btn {
    flex: 1;
    &:nth-child(1) {
      margin-right: 8px;
    }
    &:nth-child(2) {
      cursor: not-allowed;
      :deep(.common-button) {
        pointer-events: none;
      }
    }
    :deep(.common-button) {
      height: 42px !important;
      background-color: rgba(0, 0, 0, 0.05) !important;
      font-weight: 510 !important;
      font-size: 14px !important;
      line-height: 17px !important;
      color: rgba(0, 0, 0, 0.85) !important;
      transition: all 0.3s;
      &.selected {
        background-color: #ffe050 !important;
        pointer-events: unset;
      }
    }
  }
}
.first-checkbox-list {
  .first-checkbox-title {
    @include fontSemibold;
    font-size: 24px;
    line-height: 29px;
    margin-bottom: 32px;
  }
  .first-checkbox-title2 {
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
  }
  :deep(.pix-common-search) {
    margin-top: 16px;
    .el-form {
      .el-form-item {
        .el-form-item__content {
          .el-textarea__inner {
            padding: 12px;
            height: 114px !important;
          }
        }
      }
    }
    .el-form-item__label {
      color: rgba(0, 0, 0, 0.55);
    }
    .el-checkbox-group {
      display: flex;
      flex-direction: column;
      width: 100%;
      .el-checkbox {
        height: unset;
        display: flex;
        align-items: flex-start;
        margin-bottom: 12px;
        &:nth-last-child(1) {
          margin-bottom: 0;
        }
        .el-checkbox__input {
          margin-top: 1px;
          .el-checkbox__inner {
            width: 16px;
            height: 16px;
            border-radius: 2px;
            &::after {
              top: 2px;
              left: 5px;
            }
            &:hover {
              // border-color: $bg-color;
            }
          }
        }
        .el-checkbox__input.is-checked .el-checkbox__inner {
          // background-color: $bg-color;
          // border-color: $bg-color;
        }
        .el-checkbox__input.is-focus .el-checkbox__inner {
          // border-color: $bg-color;
        }
        .el-checkbox__label {
          white-space: pre-wrap;
          font-weight: 400;
          color: rgba(0, 0, 0, 0.55);
          padding-left: 8px;
          font-size: 14px;
          line-height: 20px;
        }
        &:nth-child(2) {
          align-items: flex-start;
        }
      }
    }
  }
}

.confirm-cancel {
  .confirm-cancel-title {
    @include fontSemibold;
    font-size: 24px;
    line-height: 29px;
  }
  .sub-desc {
    margin-top: 32px;
    display: flex;
    flex-direction: column;
    .sub-desc-current {
      font-weight: 400;
      font-size: 14px;
      line-height: 17px;
      color: rgba(0, 0, 0, 0.55);
      span {
        color: #000;
      }
      :deep(.name-1),
      :deep(.name-2) {
        color: #000;
      }
      &.d1 {
        margin-top: 16px;
      }
    }
  }

  .sub-desc-after {
    margin-top: 16px;
    &-title {
      font-weight: 510;
      font-size: 16px;
      line-height: 19px;
    }
    &-list {
      margin-top: 8px;
      background: #f2f2f2;
      border-radius: 16px;
      padding: 8px 8px 8px 0;
      color: rgba(0, 0, 0, 0.55);
      font-weight: 400;
      font-size: 14px;
      line-height: 17px;
      ul {
        padding-left: 23px;
        list-style: unset;
        line-height: 22px;
      }
    }
    .list-bottom-desc {
      font-weight: 400;
      font-size: 14px;
      line-height: 17px;
      color: rgba(0, 0, 0, 0.55);
      margin-top: 16px;
    }
  }
  .btn-list {
    .btn:nth-child(2) {
      cursor: pointer;
      :deep(.common-button) {
        pointer-events: unset;
      }
    }
    .btn:nth-child(2) {
      :deep(.common-button) {
        background-color: #ffe050 !important;
      }
    }
  }
  .bottom-desc {
    margin-top: 24px;
    font-weight: 400;
    font-size: 12px;
    line-height: 14px;
    color: rgba(0, 0, 0, 0.55);
  }
}

.cancel-ok {
  .cancel-ok-title {
    @include fontSemibold;
    font-size: 24px;
    line-height: 29px;
  }
  .cancel-ok-content {
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    margin-top: 32px;
    span {
      color: #1877f2;
      cursor: pointer;
    }
  }
  :deep(.common-button) {
    background-color: #ffe050;
    height: 42px;
    font-weight: 510;
    font-size: 14px;
    line-height: 17px;
    color: rgba(0, 0, 0, 0.85);
    margin-top: 40px;
  }
}
</style>

<style lang="scss">
.welcome-page-dialog {
  &.cancel-sub_dialog {
    @include device-max-width-600 {
      .content {
        width: 80%;
        .content-d {
          width: 100% !important;
        }
      }
    }

    @include device-max-width-375 {
      .content {
        width: 75%;
        .content-d {
          padding: 20px !important;
        }
        .close-icon {
          right: -32px;
        }
        .confirm-cancel,
        .first-checkbox-list {
          .btn-list {
            flex-direction: column;
            .btn:nth-child(1) {
              margin-right: 0;
              margin-bottom: 8px;
            }
          }
        }
      }
    }
  }
}
</style>
