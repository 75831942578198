<template>
  <CommonDialog
    v-model="showDialog"
    popper-class="payment-combo_sub_dialog"
    :dialog-style="dialogStyle"
    @close="onClose"
  >
    <div class="payment-combo_sub_dialog_w">
      <div class="payment-purchase-w">
        <div class="payment-purchase-w-inner">
          <div class="payment-purchase-w-t">
            <div class="t-1">{{ useTranslateI18n("payment.comboSub.t27") }}</div>
            <div class="t-2">{{ data.name }}</div>
            <div class="t-3">
              <span class="date">{{ useTranslateI18n("payment.comboSub.t28", [periodDate]) }} </span>
              <!-- <CommonTooltip popper-class="combo-sub-payment_tip_fl">
              <template #default>
                <Union />
              </template>
              <template #content>
                <span style="width: 300px; display: flex">{{ useTranslateI18n("payment.comboSub.t32") }}</span>
              </template>
            </CommonTooltip> -->
            </div>
          </div>
          <div class="payment-purchase-w-b">
            <div class="account t-1">
              <span class="t-1_1">{{ useTranslateI18n("payment.account") }}</span>
              <span class="t-1_2">{{ use_user.userEmail }}</span>
            </div>
            <div class="credit t-1">
              <span class="t-1_1">{{
                useTranslateI18n("payment.buyDataPkgDialog.credits", [useNumFormat(data.credits)])
              }}</span>
              <span class="t-1_2">
                <span class="t-1_2_s">{{ data.u_discount_per_credit_price || data.singlePrice }}</span>
                <span class="t-1_2_p">/{{ useTranslateI18n(`payment.comboPayment.carousel.photo`) }}</span>
              </span>
            </div>
            <div class="total t-1">
              <span class="t-1_1">{{ useTranslateI18n("payment.buyDataPkgDialog.total") }}</span>
              <span class="t-1_2">{{ data.u_discount_price || data.price }}</span>
            </div>
          </div>
        </div>
        <div class="payment-purchase-desc">
          <div class="payment-purchase-desc-inner">
            <div class="payment-purchase-desc-inner-item">
              <span v-html="desc"></span>
            </div>
          </div>
        </div>
      </div>
      <div class="payment-method-w">
        <div class="payment-method-w-t">
          {{ useTranslateI18n("profile.billingUsage.billing.cardData[2].title") }}
        </div>
        <div class="payment-method-w-list">
          <template v-for="(item, i) in selectRadioArr" :key="i">
            <div
              :class="[
                'payment-method-w-list-item',
                `payment-method-w-list-item-${item.value}`,
                paymentValue === item.value ? 'payment-method-w-list-item-active' : ''
              ]"
              @click="paymentSelect(item)"
            >
              <div class="list-item-l">
                <CommonImage :src="item.icon" />
              </div>
              <div class="list-item-r">
                <div class="title">{{ item.title }}</div>
                <div class="b-title">{{ item.label }}</div>
              </div>
              <div v-if="item.bg" :class="['list-item-bg', `list-item-bg-${item.value}`]">
                <CommonImage :src="item.bg" />
              </div>
            </div>
            <div v-if="item.value === Payment_Method.savedCard" class="fl"></div>
          </template>
        </div>
        <div class="submit-btn-w">
          <CommonButton ref="submitRef" :button-style="submitStyle" class="submit-btn" @btn-click="submitClick">
            <CommonAnimationScrollText>
              {{ useTranslateI18n(`payment.comboSub.t31`) }}
            </CommonAnimationScrollText>
          </CommonButton>
        </div>
      </div>
    </div>
  </CommonDialog>
</template>

<script setup lang="ts">
import { CSSProperties } from "vue"
import { Payment_Method, paymentSubMethodDict } from "@/constant/pages/combo"
import { ICardDetailData } from "@/types/components/pages/combo/comboCardT"
import { useUser } from "@/store/user"

interface DialogProps {
  onClose?: () => void
  onSubmit?: (...args: any[]) => any
  payMethods: Payment_Method[]
  data: ICardDetailData & INewComboProductListObj
  desc?: string
}
const props = withDefaults(defineProps<DialogProps>(), {
  onClose: null,
  onSubmit: null,
  payMethods() {
    return []
  },
  data() {
    return {} as ICardDetailData & INewComboProductListObj
  },
  desc() {
    return useTranslateI18n("payment.comboSub.t32", [
      { type: "static", class: "item-b" },
      { type: "static", class: "item-b" }
    ])
  }
})

const onClose = () => {
  props.onClose && props.onClose()
}

const use_user = useUser()

const periodDate = computed(() => {
  return getLocalDateString(props.data.period_end, "YYYY/MM/DD")
})

const submitStyle = reactive({
  width: "100%",
  height: "42px",
  borderRadius: "60px",
  fontSize: "16px",
  color: "#000",
  backgroundColor: "#FFE050"
})

const submitRef = ref()

const submitClick = useDebounceFn(() => {
  submitRef.value && submitRef.value.openLoading()
  props.onSubmit &&
    props.onSubmit({
      close: submitRef.value.closeLoading,
      selectValue: paymentValue.value,
      closeDialog
    })
}, 300)

const selectRadioArr = computed(() => {
  let allPayMethods = paymentSubMethodDict()
  let res = allPayMethods.filter((payItem) => {
    return props.payMethods.includes(payItem.value)
  })
  res.map((payItem) => {
    if (payItem.value === Payment_Method.savedCard) {
      payItem.label = `**** **** **** ${use_user.subscription_info_v2.card_tail}(${use_user.subscription_info_v2.expires})`
    }
  })
  return res
})

const paymentValue = ref(selectRadioArr.value[0].value)

const paymentSelect = (item: ReturnType<typeof paymentSubMethodDict>[0]) => {
  paymentValue.value = item.value
}

const showDialog = ref(true)

const closeDialog = () => {
  showDialog.value = false
}

defineExpose({
  closeDialog
})

let dialogStyle = reactive<CSSProperties>({
  width: 860,
  backgroundColor: "#fff",
  padding: "40px"
})
</script>

<style lang="scss">
.welcome-page-dialog {
  &.payment-combo_sub_dialog {
    @include device-max-width-custom(960px) {
      .content {
        width: 80%;
      }
    }

    @include device-max-width-600 {
      .content {
        width: 80%;
        .content-d {
          width: 100% !important;
        }
      }
    }
    @include device-max-width-375 {
      .content {
        width: 75%;
        .content-d {
          padding: 20px !important;
        }
        .close-icon {
          right: -32px;
        }
      }
    }
  }
}

// .combo-sub-payment_tip_fl {
//   &.credits-tips_c {
//     background-color: #2d2f31;
//     color: #fff;
//     .el-popper__arrow {
//       &::before {
//         background-color: #2d2f31;
//       }
//     }
//   }
// }
</style>
<style lang="scss" scoped>
.payment-combo_sub_dialog_w {
  display: flex;
  line-height: normal;
  .payment-purchase-w {
    padding-right: 40px;
    border-right: 1px solid rgba(0, 0, 0, 0.15);
    display: flex;
    flex-direction: column;
    width: 50%;
    .t-1 {
      font-size: 14px;
      @include fontMedium;
    }
    .t-2 {
      font-size: 24px;
      @include fontSemibold;
      margin: 8px 0;
    }
    .t-3 {
      font-size: 14px;
      color: rgba(0, 0, 0, 0.55);
      display: flex;
      align-items: center;
      .date {
        margin-right: 6px;
      }
    }

    .payment-purchase-w-b {
      margin-top: 40px;
      .t-1 {
        display: flex;
        align-items: center;
        font-size: 14px;
        justify-content: space-between;
        .t-1_2 {
          font-size: 16px;
          @include fontSemibold;
        }

        &.credit {
          margin: 18px 0;
          .t-1_2 {
            .t-1_2_s {
              font-size: 16px;
              @include fontSemibold;
            }
            .t-1_2_p {
              font-size: 14px;
              color: rgba(0, 0, 0, 0.55);
              @include fontRegular;
            }
          }
        }
      }
      .total {
        .t-1_2 {
          color: #ff6b00;
        }
      }
    }
  }

  .payment-purchase-w-inner {
    padding-bottom: 16px;
    border-bottom: 0.5px solid rgba(0, 0, 0, 0.15);
  }

  .payment-purchase-desc {
    padding-top: 50px;
    .payment-purchase-desc-inner {
      display: flex;
      flex-direction: column;
      &-item {
        color: rgba(0, 0, 0, 0.55);
        font-size: 14px;
        display: flex;
        line-height: 22px;
        :deep(.item-b) {
          color: rgba(0, 0, 0, 0.85);
        }
        &::before {
          content: "";
          display: inline-block;
          width: 5px;
          height: 5px;
          border-radius: 50%;
          background: rgba(0, 0, 0, 0.85);
          flex-shrink: 0;
          margin-right: 10px;
          margin-top: 8px;
        }
      }
    }
  }

  .payment-method-w {
    width: 50%;
    padding-left: 40px;
    display: flex;
    flex-direction: column;
    &-t {
      margin-bottom: 24px;
      font-size: 14px;
      @include fontMedium;
    }
    &-list {
      display: flex;
      flex-direction: column;
      gap: 24px;
      .fl {
        height: 0.5px;
        background-color: rgba(0, 0, 0, 0.15);
      }
      &-item {
        display: flex;
        align-items: center;
        padding: 12px 16px;
        border-radius: 6px;
        cursor: pointer;
        position: relative;
        z-index: 1;
        &::after {
          @include ani-linear;
          content: "";
          border-radius: inherit;
          position: absolute;
          left: 0;
          top: 0;
          width: 100%;
          height: 100%;
          z-index: 1;
          pointer-events: none;
        }
        .list-item-l {
          width: 40px;
          height: 40px;
          margin-right: 16px;
          position: relative;
          flex-shrink: 0;
          z-index: 1;
        }
        .list-item-r {
          line-height: normal;
          display: flex;
          position: relative;
          flex-direction: column;
          z-index: 1;
          .title {
            font-size: 12px;
            @include fontMedium;
            margin-bottom: 4px;
            color: rgba(0, 0, 0, 0.55);
          }
          .b-title {
            font-size: 14px;
            color: #000;
          }
        }
        .list-item-bg {
          position: absolute;
          z-index: 0;
          pointer-events: none;
          &.list-item-bg-1 {
            width: 62px;
            height: 48px;
            top: -3px;
            left: -3px;
          }
          &.list-item-bg-2 {
            width: 100.5px;
            aspect-ratio: 201/102;
            bottom: -3px;
            left: 4px;
          }
        }
      }

      &-item-3 {
        background-color: #fff4eb;
        &:hover {
          &::after {
            box-shadow: 0 0 0 2px #fea154 inset;
          }
        }
        &.payment-method-w-list-item-active {
          &::after {
            box-shadow: 0 0 0 2px #fea154 inset;
          }
        }
      }
      &-item-1 {
        background-color: #e3f0ff;
        &:hover {
          &::after {
            box-shadow: 0 0 0 2px #165dff inset;
          }
        }
        &.payment-method-w-list-item-active {
          &::after {
            box-shadow: 0 0 0 2px #165dff inset;
          }
        }
      }
      &-item-2 {
        background-color: #f3efff;
        &:hover {
          &::after {
            box-shadow: 0 0 0 2px #865bff inset;
          }
        }
        &.payment-method-w-list-item-active {
          &::after {
            box-shadow: 0 0 0 2px #865bff inset;
          }
        }
      }
    }
  }

  .submit-btn-w {
    display: flex;
    margin-top: 40px;
  }
}

@include device-max-width-custom(960px) {
  .payment-combo_sub_dialog_w {
    display: flex;
    flex-direction: column;
    .payment-purchase-w,
    .payment-method-w {
      width: 100%;
    }

    .payment-purchase-w {
      padding-right: 0;
      border-right: 0;
      border-bottom: 1px solid rgba(0, 0, 0, 0.15);
      padding-bottom: 20px;
    }
    .payment-method-w {
      padding-left: 0;
      margin-top: 20px;
    }
  }
}
</style>
